<template>
  <div>
    <v-container class="accent white--text" v-if="$vuetify.breakpoint.mobile">
      <v-row align="center">
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="d-flex flex-column justify-space-between align-center">
            <v-img src="@/assets/landing-page/computer1.jpg" max-width="100%" />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="white--text text--center"></div>
          <div class="font-weight-bold mb-4 title1" style="line-height: 1">
            VOCÊ ESTÁ À ALGUNS PASSOS PARA TESTAR GRATUITAMENTE O SEU eCONSENSO.
          </div>
          <div class="font-weight-light subtitle1">
            Através do eCONSENSO você poderá Solicitar e Registrar
            Consentimentos com toda Segurança e Comodidade.
          </div>
          <v-btn
            rounded
            outlined
            large
            dark
            class="mt-5 my-8"
            @click="formDialog.active = true"
          >
            LIBERAR TESTE GRATUITO
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet dark class="accent" v-else>
      <v-row align="center" justify="center" class="pt-16 pb-4" style="width: 83%; margin: 0 auto;">
        <v-col cols="12" sm="6" md="6" xl="6" align="end">
          <div class="font-weight-bold mb-4 title1" style="line-height: 1">
            VOCÊ ESTÁ À ALGUNS PASSOS<br />
            PARA TESTAR GRATUITAMENTE<br />
            O SEU eCONSENSO.
          </div>
          <div class="font-weight-light subtitle1">
            Através do eCONSENSO você poderá<br />
            Solicitar e Registrar Consentimentos<br />com toda Segurança e
            Comodidade.
          </div>
          <v-btn
            rounded
            outlined
            large
            dark
            class="mt-5"
            @click="formDialog.active = true"
          >
            EU QUERO LIBERAR O MEU TESTE GRATUITO
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" xl="6" class="hidden-sm-and-down">
          <div class="d-flex flex-column justify-space-between align-start">
            <v-img
              src="@/assets/landing-page/computer1.jpg"
              max-width="80%"
            />
          </div>
        </v-col>
      </v-row>
      <v-img
        src="@/assets/landing-page/borderWaves.svg"
        height="3rem"
      />
    </v-sheet>

    <v-row align="center" justify="center">
      <v-col cols="10">
        <div
          class="font-weight-bold title1 black--text mt-8"
          align="center"
          style="line-height: 1"
        >
          Neste teste gratuito você terá aceso à:
        </div></v-col
      ></v-row
    >

    <v-container fluid class="mt-4">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular" style="line-height: 1">
                    {{ feature.title }}
                  </h1>
                  <div class="font-weight-regular subtitle-1 mt-4">
                    {{ feature.text }}
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-row align="center" justify="space-around">
      <v-col cols="10">
        <div
          align="center"
          class="font-weight-bold title1 black--text mt-8"
          style="line-height: 1"
        >
          Solicite AGORA MESMO seu Teste Gratuito e receba, diretamente no
          E-mail, todas as informações:
        </div></v-col
      ></v-row
    >

    <div align="center" class="pb-10">
      <v-btn
        rounded
        large
        dark
        class="mt-5 my-10 accent"
        @click="formDialog.active = true"
      >
        <template v-if="!$vuetify.breakpoint.mobile">
          EU QUERO LIBERAR O MEU TESTE GRATUITO
        </template>
        <template v-else>
          LIBERAR TESTE GRATUITO
        </template>
      </v-btn>
    </div>
    
    <v-dialog
      v-model="formDialog.active"
      max-width="500px"
      :persistent="formDialog.loading"
    >
      <v-card>
        <div align="end">
          <v-btn
            icon
            @click="formDialog.active = false"
            :disabled="formDialog.loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0 justify-center">
          <div align="center" class="subtitle1 ma-5 font-weight-bold break">
            Falta muito pouco para você ter acesso ao seu TESTE GRATUITO DO
            eCONSENSO. Responda ao formulário abaixo e receba no seu e-mail
            todas as orientações.
          </div>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="leadsForm">
            <v-text-field
              label="Nome *"
              v-model="formDialog.data.name"
              dense
              class="mt-2"
              :rules="[$rules.required]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E-Mail *"
              v-model="formDialog.data.email"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.email]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Telefone *"
              v-model="formDialog.data.phone"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.phone]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Qual a sua Empresa atualmente?"
              v-model="formDialog.data.company"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E qual a sua posição nessa Empresa?"
              v-model="formDialog.data.company_position"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-checkbox
              label="Concordo em receber comunicações"
              :disabled="formDialog.loading"
              :rules="[(v) => !!v || 'Você deve concordar para continuar']"
            />
            <p class="text-subtitle-2 grey--text text--darken-1">
              Uma mensagem com mais informações será enviada para seu e-mail.
              Confira também sua caixa de spam.
            </p>
            <div align="center" justify="center">
              <v-btn
                class="my-2"
                block
                large
                color="success"
                :loading="formDialog.loading"
                @click="sendForm()"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  name: "LandingFreeTrial",
  components: {
    Footer,
  },
  data() {
    return {
      formDialog: {
        active: false,
        loading: false,
        data: {
          name: "",
          email: "",
          phone: "",
          company: "",
          company_position: "",
        },
      },
      features: [
        {
          img: require("@/assets/landing-page/icon1.png"),
          title: "Acesso à Plataforma e Aplicativo do eConsenso",
          text: "Você poderá acessar a plataforma, cadastrar a sua Empresa e Clientes, além de seus Clientes poderem utilizar o app para responder aos Consentimentos solicitados.",
        },
        {
          img: require("@/assets/landing-page/icon2.png"),
          title: "50 Consentimentos GRÁTIS",
          text: "Os 50 Consentimentos são disponibilizados assim que a Plataforma for configurada e liberada para sua utilização. Esses Consentimentos não possuem data de Expiração e não são cumulativos.",
        },
        {
          img: require("@/assets/landing-page/icon3.png"),
          title: "Acesso à nossa Equipe de Suporte/Consultoria",
          text: "Nossa Equipe de Suporte/Consultoria lhe dará orientações com relação à utilização da Plataforma e Aplicativo.",
        },
      ],
    };
  },
  methods: {
    sendForm() {
      if (this.$refs.leadsForm.validate()) {
        this.formDialog.loading = true;
        const queryParams = Object.entries(this.$route.query).map(([key, val]) => `${key}=${val}`)
        this.$axios
          .post("/lead", {
            ...this.formDialog.data,
            stage: "Solicitação de Agendamento",
            tags: ["LP Teste Gratuito", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Você está prestes a ter acesso ao seu Teste Gratuito!",
              "Enviamos ao seu e-mail mais informações e procedimentos para realizar o seu Teste Gratuito. Fique atento, e caso possua interesse estamos disponíveis por Whatsapp/Telefone no número (48) 4042-3534. Até mais!"
            );
            this.$refs.leadsForm.reset();
            this.formDialog.active = false;
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado ao salvar os dados. Tente novamente ou contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.formDialog.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>

<style scoped>
.break {
  word-break: break-word;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}
.card {
  height: 100%;
  padding: 20px;
  transition: 0.5s ease-out;
}
.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.zoom-efect {
  transform: scale(1.1);
}
.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.title1 {
  font-size: 34px;
  font-weight: bold;
}
.subtitle1 {
  font-size: 21px;
}
</style>
